import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebookSquare,
  faTwitter,
  faInstagram,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import { NavLink } from 'react-bootstrap';


export const FooterContent: React.FC = () => {
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <footer className='mt-auto'>
      <div id="FooterMain" className='col-md-12 col-lg-12 col-xl-12 px-3'>
        <div className="col-md-3 col-lg-3 col-xl-3 mt-3 ms-4">
          <img alt="Logo" className="footer-logo mt-3" src="/footerlogo.png"></img>
          <NavLink disabled className='text-start text-break col-md-8 mt-2'>
            Jl. Wijaya Kusuma no.19, RT.003/,
            RW.010 Poris, Plawad Indah,
            Cipondoh, Kota Tangerang,
            Banten 15141
          </NavLink>
          <div className='col-md-3 col-lg-3 col-xl-3 d-flex mt-2'>
            <NavLink onClick={() => openInNewTab("https://instagram.com/petir.logistic/")}><FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon></NavLink>
            <NavLink onClick={() => openInNewTab("https://facebook.com")}><FontAwesomeIcon icon={faFacebookSquare}></FontAwesomeIcon></NavLink>
            <NavLink onClick={() => openInNewTab("https://linkedin.com")}><FontAwesomeIcon icon={faLinkedin}></FontAwesomeIcon></NavLink>
            <NavLink onClick={() => openInNewTab("https://twitter.com")}><FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon></NavLink>
            <NavLink onClick={() => openInNewTab("https://youtube.com")}><FontAwesomeIcon icon={faYoutube}></FontAwesomeIcon></NavLink>
          </div>
        </div>
        <div className="col-md-3 col-lg-3 col-xl-3 mt-3 pb-5 pt-4 mx-3">
          <h5 id="textFooter2" className="font-weight-bold">Perusahaan</h5>
          <NavLink className='mt-2' href="/#TentangPerusahaan" id="textFooter">Profil Perusahaan</NavLink>
          <NavLink className='mt-2' onClick={() => openInNewTab("https://wa.me/628997088887")} id="textFooter">Gabung Kemitraan</NavLink>
          <NavLink className='mt-2' onClick={() => openInNewTab("https://wa.me/628997088887")} id="textFooter">Hubungi Kami</NavLink>
        </div>

        <div className="col-md-3 col-lg-3 col-xl-3 mx-3 mt-3 pb-5 pt-4">
          <h5 id="textFooter2" className="font-weight-bold">Produk & Layanan</h5>
          <NavLink className='mt-2' href='/#backgroundProduk' id="textFooter">Reguler</NavLink>
          <NavLink className='mt-2' href='/#backgroundProduk' id="textFooter">Ekspress</NavLink>
        </div>

        <div className="col-md-3 col-lg-3 col-xl-3 me-4 mt-3 pb-5 pt-4">
          <h5 id="textFooter2" className="font-weight-bold">&nbsp;Link Terkait</h5>
          <NavLink className='mt-2' id="textFooter" href='/#red'>Lacak Paket</NavLink>
          <NavLink className='mt-2' id="textFooter" href='/cektarif'>Cek Tarif Pengiriman</NavLink>
          <NavLink className='mt-2' id="textFooter" href='/#mitraNav'>Mitra Kami</NavLink>
        </div>
      </div>

      <div id="FooterMainCopyRight" className="text-center border-top border-dark">
        <a>Copyright Petir Logistic 2022 | All Rights Reserved.</a>
      </div>

    </footer>
  );
}