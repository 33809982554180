import React from "react";
import Head from 'next/head';
import { Navbar } from "./Navbar";
import { FooterContent } from "./Footer";
import CookieBanner from "./CookieBanner";

const DefaultLayout: React.FC<{
    children: React.ReactNode
}> = ({ children }) => {
    return (
        <div className="browser-frame">
            <Head>
                <meta key="meta-charset" charSet="utf-8" />
                <meta key="meta-viewport" name="viewport" content="width=device-width, initial-scale=1" />
                <link key="favicon" rel="icon" href="/favicon.ico" />
            </Head>
            <Navbar></Navbar>
            <div className="row">
                <main className="mb-3">
                    {children}
                </main>
            </div>
            <CookieBanner />
            <FooterContent></FooterContent>
        </div>
    );
}

export const WithDefaultLayout = (page: React.ReactElement) => <DefaultLayout>{page}</DefaultLayout>;