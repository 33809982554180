"use client";
import { useEffect, useState } from "react";
import { getLocalStorage, setLocalStorage } from "./Helper";

export default function CookieBanner() {
  const [cookieConsent, setCookieConsent] = useState<boolean | null>(null);
  const [isRendered, setIsRendered] = useState<boolean>(false);

  useEffect(() => {
    const storedCookieConsent = getLocalStorage("cookie_consent", null);
    setCookieConsent(storedCookieConsent);

    if(storedCookieConsent === null)
    {
        setIsRendered(true);
    }
    else
    {
        setIsRendered(false);
    }
  }, [setCookieConsent]);

  useEffect(() => {
    setLocalStorage("cookie_consent", cookieConsent);
  }, [cookieConsent]);

  // place the whatsapp button here to make it reactive with cookie
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <>
        <div
        id="cookieBanner"
        className={`${
            isRendered === false
            ? "d-none"
            : "container-fluid stacked-nav fixed-bottom bg-black"
        }`}
        >
            <div className="row flex-centered flex-wrap justify-content-between py-3 px-2">
                <p className="col-md-9 col-sm-12 flex-centered my-2 text-white">
                    Kami menggunakan cookie untuk meningkatkan pengalaman Anda di situs web kami. Klik "Terima Cookie" bila Anda setuju menggunakan cookie di web kami.
                </p>

                <div className="col-md-3 col-sm-12 flex-centered my-2">
                        <button
                            onClick={() => {
                                setCookieConsent(true);
                                setIsRendered(false);
                            }}
                            type="button"
                            className="btn btn-danger text-white mx-1"
                            id="red"
                        >
                            Terima Cookie
                        </button>
                        <button
                            onClick={() => {
                                setCookieConsent(false);
                                setIsRendered(false);
                            }}
                            type="button"
                            id="cookieBanner"
                            className="btn border-danger border-2 text-white mx-1"
                            
                        >
                            Tolak Cookie
                        </button>
                </div>
            </div>
        </div>

        {/* whatsapp button */}
        <a
            onClick={() => openInNewTab("https://wa.me/628997088887")}
            className={`${
                isRendered === false
                ? "float"
                : "float whatsapp-btn"
            }`}>
            <img className="my-float" alt="wabutton" src="/WhatsApp.png"></img>
        </a>
    </>
  );
}