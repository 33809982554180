import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from '../components/NavLink';
import Link from 'next/link';
import Dropdown from 'react-bootstrap/Dropdown';
import { UnauthenticatedTemplate } from '@azure/msal-react';
import { useRouter } from 'next/router';
import { useAuthorizedAxios } from '../functions/useAuthorizedAxios';

export const Navbar: React.FC = () => {
    const [user, setUser] = useState('');
    const router = useRouter();
    const axiosClient = useAuthorizedAxios();

    useEffect(() => {
        const data = async () => {
            const response = await axiosClient.get(`/api/main-be/api/v1/Auth/user-profile`);
            if (response.data && response.status == 200) {
                setUser(response.data.name);
            }
        }
        data();
    }, [axiosClient])

    function CustomToggle({ children, onClick }) {
        return (
            <button
                type="button"
                className="btn btn-link dropdownButtonToggleNavbar"
                onClick={onClick}
            >
                {children}

            </button>
        );
    }

    const signIn = async () => {
        router.push('/login/loginform');
    }

    const register = async () => {
        router.push('/register');
    }

    const signOut = async () => {
        localStorage.removeItem('user');
        router.push('/login/loginform');
        // NextSignOut({callbackUrl: '/login/loginform'})
    }

    return (
        <div className="container-fluid stacked-nav fixed-top">
            <header id="navbar" className="row flex-centered flex-wrap justify-content-lg-between py-3 ">
                <Link href="/#berandaHalaman">
                    <a className="col-12 col-lg-3 flex-centered justify-content-lg-start mb-2 mb-lg-0 text-decoration-none">
                        <img alt="Logo" className="navbar-logo ms-lg-5" src="/PetirLogisticLogo.png"></img>
                    </a>
                </Link>
                <ul className="nav col-12 col-lg-auto flex-centered mb-2 mb-lg-0">
                    <li className='nav-item'>
                        <NavLink href='/#berandaHalaman' style={{ textDecoration: 'none' }}>Beranda</NavLink>
                    </li>
                    <li className='nav-item'>
                        <NavLink href='/#TentangPerusahaan' style={{ textDecoration: 'none' }}>Tentang Kami</NavLink>
                    </li>
                    <li className='nav-item'>
                        <NavLink href='/#backgroundProduk' style={{ textDecoration: 'none' }}>Produk & Layanan</NavLink>
                    </li>
                    <li className='nav-item'>
                        <NavLink href="https://wa.me/628997088887" style={{ textDecoration: 'none' }}>Hubungi Kami</NavLink>
                    </li>
                </ul>
                <div className="col-12 col-lg-3 flex-centered justify-content-lg-end me-3">
                    {
                        Object.keys(user).length === 0 &&
                        <UnauthenticatedTemplate>
                            {/* <NavLink href='/login/loginform'>Masuk</NavLink> */}
                            <button type="button" onClick={signIn} className="text-light mx-3 border-0 buttonLoginStyle" id="fontMasuk">
                                {/* <FontAwesomeIcon icon={faSignInAlt} className='me-2'></FontAwesomeIcon> */}
                                Masuk
                            </button>

                            <button type="button" onClick={register} className="btn btn-warning" id="text-col">
                                {/* <FontAwesomeIcon icon={faSignInAlt} className='me-2'></FontAwesomeIcon> */}
                                Daftar
                            </button>
                        </UnauthenticatedTemplate>
                    }

                    {
                        Object.keys(user).length > 0 &&
                        <div className='borderNavbarLoginStyle'>
                            <Dropdown align='end'>
                                <Dropdown.Toggle as={CustomToggle} variant='link' style={{
                                    color: 'white',
                                    textDecoration: 'none'
                                }}>


                                    <div className='navbarBorderUsername'>
                                        Hello, {user}!
                                    </div>

                                </Dropdown.Toggle>

                                <Dropdown.Menu className='dropdownMenuNavbar'>
                                    <Dropdown.Item className='ItemDropdownPengaturanHover' href='/User/profile'>
                                        Pengaturan
                                    </Dropdown.Item>
                                    <Dropdown.Item className='ItemDropdownPengaturanHover' href='/User/pesanansaya'>
                                        Pesanan saya
                                    </Dropdown.Item>
                                    <Dropdown.Item className='dropdownItemKeluar' onClick={() => signOut()}>
                                        <FontAwesomeIcon icon={faSignOutAlt} className='me-2'></FontAwesomeIcon>
                                        Keluar
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                        </div>
                    }
                </div>
            </header>
        </div>
    );
};
