import axios, { AxiosInstance } from "axios";
import { useMemo } from "react";
import { DefaultAxiosRequestHeader } from "./DefaultAxiosRequestHeader";


/**
 * This hook can only be used inside `<Authorize>` component.
 * Caching is disabled via `DefaultAxiosRequestHeader` object.
 * @returns Axios Instance object with Authorization Bearer Access Token set.
 */
export function useAuthorizedAxios(): AxiosInstance {
    
    if (typeof window !== 'undefined') {
        const user = localStorage.getItem('user');
        if(user != null){
            const objUser = JSON.parse(user);
            var token = objUser['data'].token;
        }
    }

    const client = useMemo(() => {
        return axios.create({
            headers: {
                ...DefaultAxiosRequestHeader,
                'Authorization': `Bearer ${token}`
            }
        });
    }, [token]);

    return client;

}
